import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class UserWorkbookService {
  private apiPrefix = '/client/user-workbooks';

  constructor(
    private api: ApiService
  ) {
  }

  createWorkbook(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  updateWorkbook(userWorkbookId: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${userWorkbookId}`, data);
  }

  getWorkbook(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}`);
  }

  getWorkbooks(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  getWorkbookTopics(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/topics`);
  }

  updateWorkbookTopics(id: number, data: object) {
    return this.api.put(`${this.apiPrefix}/${id}/topics`, data);
  }

  deleteWorkbook(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  getNotCompletedTopics(qs = '') {
    return this.api.get(`${this.apiPrefix}/all/not-completed-topics${qs}`);
  }

  getWorkbooksWithFilter(qs: string = '') {
    return this.api.get(`${this.apiPrefix}/all/with-lessons${qs}`);
  }

  getWorkbookTopicsWithFilter(qs: string = '') {
    return this.api.get(`${this.apiPrefix}/all/topics${qs}`);
  }

  completeTopic(topicId: number) {
    return this.api.post(`${this.apiPrefix}/all/topics/${topicId}/complete`, {});
  }

  getTopicWorkbooks(topicId: number) {
    return this.api.get(`${this.apiPrefix}/all/topics/${topicId}`);
  }

  updateWorkbookTopic(id: number, topicId: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${id}/topics/${topicId}`, data);
  }

  notCompletedLessons(): Observable<any> {
    return this.api.get(`${this.apiPrefix}/all/not-completed-lessons`);
  }
}
